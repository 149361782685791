import './config.js';
import './error-handling.js';
import { get_session, get_username, show_signup, show_login } from './auth.js';

import $ from 'jquery';

import './brokenwrenches.scss';
import './fonts.css';
import './material-icons.css';

function getRequestedUsername(){
    var [r, user, category, item] = window.location.pathname.split('/');
    return user;
}

//
// Login and Cookie Related functions
//

//
// Functions that interact with the API
//

function addWaitlistEntry(email, interests){
    return $.ajax({
        url: window._config.apiUrl + '/waitlist',
        type: 'PUT',
        contentType: 'application/json',
        processData: false,
        data: {'email': email, 'interests': interests},
        beforeSend: function(xhr, options){
            if (options.data){
                options.data=JSON.stringify(options.data);
            }
        },
        error: function(x, s, e){
            console.error(this.url + ' xhr: ' + JSON.stringify(x));
            console.error(this.url + ' status: ' + s);
            console.error(this.url + ' error: ' + e);
        }
    });
}

function refer_email(email, message){
    return get_session(function(){
        return $.ajax({
            url: window._config.apiUrl + '/referral',
            type: 'POST',
            contentType: 'application/json',
            processData: false,
            data: {'email': email, 'message': message},
            headers: {
                'Authorization': window._token
            },
            beforeSend: function(xhr, options){
                if (options.data){
                    options.data=JSON.stringify(options.data);
                }
            },
            error: function(x, s, e){
                console.error(this.url + ' xhr: ' + JSON.stringify(x));
                console.error(this.url + ' status: ' + s);
                console.error(this.url + ' error: ' + e);
            }
        })
    });
}

function send_feedback(feedback, followup){
    return get_session(function(){
        return $.ajax({
            url: window._config.apiUrl + '/feedback',
            type: 'POST',
            contentType: 'application/json',
            processData: false,
            data: {'feedback': feedback, 'followup': followup},
            headers: {
                'Authorization': window._token
            },
            beforeSend: function(xhr, options){
                if (options.data){
                    options.data=JSON.stringify(options.data);
                }
            },
            error: function(x, s, e){
                console.error(this.url + ' xhr: ' + JSON.stringify(x));
                console.error(this.url + ' status: ' + s);
                console.error(this.url + ' error: ' + e);
            }
        })
    });
}

export function increment_metric(metric, dimensions){
    if (typeof dimensions === 'undefined'){
        var dimensions = [];
    }

    return $.ajax({
        url: window._config.apiUrl + '/metrics/' + metric,
        type: 'PUT',
        contentType: 'application/json',
        processData: false,
        data: {'dimensions': dimensions},
        beforeSend: function(xhr, options){
            if (options.data){
                options.data=JSON.stringify(options.data);
            }
        },
        error: function(x, s, e){
            console.error(this.url + ' xhr: ' + JSON.stringify(x));
            console.error(this.url + ' status: ' + s);
            console.error(this.url + ' error: ' + e);
        }
    });
}

function create_category(username, category_name, image, category_shortname, description){
    var data = {'category_name': category_name};

    if (category_shortname){
        data['category_shortname'] = category_shortname;
    }

    if (description){
        data['description'] = description;
    }

    if (image){
        data['image_filename'] = image.name;
        data['mime_type'] = image.type;

        $('.splash_error').append('Uploading image. <progress class=create_progress></progress>');
        var $create_progress = $('.splash_error').find('.create_progress');

        return get_session(function(){
            return $.ajax({
                url: window._config.apiUrl + '/users/' + username + '/categories',
                type: 'POST',
                contentType: 'application/json',
                processData: false,
                data: data,
                headers: {
                    'Authorization': window._token
                },
                beforeSend: function(xhr, options){
                    if (options.data){
                        options.data=JSON.stringify(options.data);
                    }
                },
                error: function(x, s, e){
                    console.error(this.url + ' xhr: ' + JSON.stringify(x));
                    console.error(this.url + ' status: ' + s);
                    console.error(this.url + ' error: ' + e);
                },
                success: function(response_data){
                    if (response_data['status'] == 'OK'){
                        var form_data = new FormData();
                        for (let key in response_data['post_parameters']['fields']){
                            form_data.append(key, response_data['post_parameters']['fields'][key]);
                        }
                        form_data.append('file', image);

                        $.ajax({
                            url: response_data['post_parameters']['url'],
                            data: form_data,
                            type: 'POST',
                            contentType: false,
                            processData: false,
                            xhr: function(){
                                var xhr = new window.XMLHttpRequest();
                                xhr.upload.addEventListener("progress", function(e){
                                    if (e.lengthComputable){
                                        $create_progress.attr({'value': e.loaded, 'max': e.total});
                                    }
                                }, false);

                                return xhr;
                            },
                            error: function(x, s, e){
                                console.error(this.url + ' xhr: ' + JSON.stringify(x));
                                console.error(this.url + ' status: ' + s);
                                console.error(this.url + ' error: ' + e);
                            },
                            success: function(){
                                user_home();
                                splash_close();
                            }
                        })
                    }
                }
            })
        });
    } else {
        return get_session(function(){
            return $.ajax({
                url: window._config.apiUrl + '/users/' + username + '/categories',
                type: 'POST',
                contentType: 'application/json',
                processData: false,
                data: data,
                headers: {
                    'Authorization': window._token
                },
                beforeSend: function(xhr, options){
                    if (options.data){
                        options.data=JSON.stringify(options.data);
                    }
                },
                error: function(x, s, e){
                    console.error(this.url + ' xhr: ' + JSON.stringify(x));
                    console.error(this.url + ' status: ' + s);
                    console.error(this.url + ' error: ' + e);
                },
                success: function(response_data){
                    if (response_data['status'] == 'OK'){
                        user_home();
                        splash_close();
                    }
                }
            })
        });
    }
}

function update_category(username, category_id, category_name, image, category_shortname, description, public_){
    var data = {'category_name': category_name};

    if (category_shortname != null){
        data['category_shortname'] = category_shortname;
    }

    if (description != null){
        data['description'] = description;
    }

    if (typeof public_ !== 'undefined'){
        data['public'] = public_;
    }

    if (image){
        data['image_filename'] = image.name;
        data['mime_type'] = image.type;

        $('.splash_error').append('Uploading image. <progress class=create_progress></progress>');
        var $create_progress = $('.splash_error').find('.create_progress');

        return get_session(function(){
            return $.ajax({
                url: window._config.apiUrl + '/users/' + username + '/categories/' + category_id,
                type: 'PUT',
                contentType: 'application/json',
                processData: false,
                data: data,
                headers: {
                    'Authorization': window._token
                },
                beforeSend: function(xhr, options){
                    if (options.data){
                        options.data=JSON.stringify(options.data);
                    }
                },
                error: function(x, s, e){
                    console.error(this.url + ' xhr: ' + JSON.stringify(x));
                    console.error(this.url + ' status: ' + s);
                    console.error(this.url + ' error: ' + e);
                },
                success: function(response_data){
                    if (response_data['status'] == 'OK'){
                        var form_data = new FormData();
                        for (let key in response_data['post_parameters']['fields']){
                            form_data.append(key, response_data['post_parameters']['fields'][key]);
                        }
                        form_data.append('file', image);

                        $.ajax({
                            url: response_data['post_parameters']['url'],
                            data: form_data,
                            type: 'POST',
                            contentType: false,
                            processData: false,
                            xhr: function(){
                                var xhr = new window.XMLHttpRequest();
                                xhr.upload.addEventListener("progress", function(e){
                                    if (e.lengthComputable){
                                        $create_progress.attr({'value': e.loaded, 'max': e.total});
                                    }
                                }, false);

                                return xhr;
                            },
                            error: function(x, s, e){
                                console.error(this.url + ' xhr: ' + JSON.stringify(x));
                                console.error(this.url + ' status: ' + s);
                                console.error(this.url + ' error: ' + e);
                            },
                            success: function(){
                                load_category(username, response_data['id'], true)
                                splash_close();
                            }
                        })
                    }
                }
            })
        });
    } else {
        return get_session(function(){
            return $.ajax({
                url: window._config.apiUrl + '/users/' + username + '/categories/' + category_id,
                type: 'PUT',
                contentType: 'application/json',
                processData: false,
                data: data,
                headers: {
                    'Authorization': window._token
                },
                beforeSend: function(xhr, options){
                    if (options.data){
                        options.data=JSON.stringify(options.data);
                    }
                },
                error: function(x, s, e){
                    console.error(this.url + ' xhr: ' + JSON.stringify(x));
                    console.error(this.url + ' status: ' + s);
                    console.error(this.url + ' error: ' + e);
                },
                success: function(response_data){
                    if (response_data['status'] == 'OK'){
                        load_category(username, response_data['id'], true)
                        splash_close();
                    }
                }
            })
        });
    }
}

function get_categories(username, callable){
    if (!username){
        var username = get_username();
    }

    return get_session(function(){
        return $.ajax({
            url: window._config.apiUrl + '/users/' + username + '/categories',
            type: 'GET',
            contentType: 'application/json',
            processData: false,
            headers: {
                'Authorization': window._token
            },
            xhrFields: {
                withCredentials: true
            },
            beforeSend: function(xhr, options){
                if (options.data){
                    options.data=JSON.stringify(options.data);
                }
            },
            error: function(x, s, e){
                console.error(this.url + ' xhr: ' + JSON.stringify(x));
                console.error(this.url + ' status: ' + s);
                console.error(this.url + ' error: ' + e);
                if (x.status == 403){
                    let html = 'The item is unavailable, just like a 10MM socket when you need it. '
                    html = html.concat('Please visit us at <a href=/>brokenwrenches.com</a>.');
                    splash(html, true);
                }
            },
            success: callable
        })
    }, function(){show_login(window.location.pathname)});
}

function delete_category_by_id(category_id){
    if (!username){
        var username = get_username();
    }

    return get_session(function(){
        return $.ajax({
            url: window._config.apiUrl + '/users/' + username + '/categories/' + category_id,
            type: 'DELETE',
            contentType: 'application/json',
            processData: false,
            headers: {
                'Authorization': window._token
            },
            beforeSend: function(xhr, options){
                if (options.data){
                    options.data=JSON.stringify(options.data);
                }
            },
            error: function(x, s, e){
                console.error(this.url + ' xhr: ' + JSON.stringify(x));
                console.error(this.url + ' status: ' + s);
                console.error(this.url + ' error: ' + e);
            }
        })
    });
}

function get_category_by_id(user, category_id, limit, callable){

    let data;
    if (limit > 0){
        data = {'limit': limit}
    } else {
        data = {}
    }

    // this uses processData true, where everything else is false
    return get_session(function(){
        return $.ajax({
            url: window._config.apiUrl + '/users/' + user + '/categories/' + category_id,
            type: 'GET',
            contentType: 'application/json',
            processData: true,
            data: data,
            headers: {
                'Authorization': window._token
            },
            xhrFields: {
                withCredentials: true
            },
            beforeSend: function(xhr, options){
                if (options.data){
                    options.data=JSON.stringify(options.data);
                }
            },
            error: function(e){
                if (e.status == 403){
                    let html = 'The item is unavailable, just like a 10MM socket when you need it. '
                    html = html.concat('Please visit us at <a href=/>brokenwrenches.com</a>.');
                    splash(html, true);
                }
            },
            success: callable
        })
    });
}

function get_item_by_id(username, category_id, item_id, callable){
    // this uses processData true, where everything else is false
    return get_session(function(){
        return $.ajax({
            url: window._config.apiUrl + '/users/' + username + '/categories/' + category_id + '/items/' + item_id,
            type: 'GET',
            contentType: 'application/json',
            processData: true,
            headers: {
                'Authorization': window._token
            },
            xhrFields: {
                withCredentials: true
            },
            beforeSend: function(xhr, options){
                if (options.data){
                    options.data=JSON.stringify(options.data);
                }
            },
            error: function(e){
                if (e.status == 403){
                    let html = 'The item is unavailable, just like a 10MM socket when you need it. '
                    html = html.concat('Please visit us at <a href=/>brokenwrenches.com</a>.');
                    splash(html, true);
                }
            },
            success: callable
        })
    });
}

function upload_item(username, category_id, type, image, caption, description){
    var data = { 'type': type };

    if (caption){
        data['caption'] = caption;
    }

    if (description){
        data['description'] = description;
    }

    if (image){
        data['image_filename'] = image.name;
        data['mime_type'] = image.type;
        var $progress_bar = create_progress_indicator(category_id, true);
    }

    get_session(function(){
        return $.ajax({
            url: window._config.apiUrl + '/users/' + username + '/categories/' + category_id + '/items',
            type: 'POST',
            contentType: 'application/json',
            processData: false,
            data: data,
            headers: {
                'Authorization': window._token
            },
            beforeSend: function(xhr, options){
                if (options.data){
                    options.data=JSON.stringify(options.data);
                }
            },
            error: function(x, s, e){
                console.error(this.url + ' xhr: ' + JSON.stringify(x));
                console.error(this.url + ' status: ' + s);
                console.error(this.url + ' error: ' + e);
            },
            success: function(response_data){
                if (response_data['post_parameters']){
                    var form_data = new FormData();
                    for (let key in response_data['post_parameters']['fields']){
                        form_data.append(key, response_data['post_parameters']['fields'][key]);
                    }
                    form_data.append('file', image);

                    $progress_bar.parent().attr('id', response_data['id'] + '_progress');

                    $.ajax({
                        url: response_data['post_parameters']['url'],
                        data: form_data,
                        type: 'POST',
                        contentType: false,
                        processData: false,
                        xhr: function(){
                            var xhr = new window.XMLHttpRequest();
                            xhr.upload.addEventListener("progress", function(e){
                                if (e.lengthComputable){
                                    $progress_bar.attr({'value': e.loaded, 'max': e.total});
                                }
                            }, false);

                            return xhr;
                        },
                        error: function(x, s, e){
                            console.error(this.url + ' xhr: ' + JSON.stringify(x));
                            console.error(this.url + ' status: ' + s);
                            console.error(this.url + ' error: ' + e);
                        },
                        success: function(){
                            add_item_to_category(category_id, response_data['id'], response_data['image_id'], '', caption, description, true, undefined, type);
                        }
                    })
                } else {
                    add_item_to_category(category_id, response_data['id'], undefined, '', caption, description, true, undefined, type);
                }
            }
        })
    });
}

function update_image(username, category_id, image_id, date, caption, description, public_){
    var data = {};

    if (date){
        data['date'] = date;
    }

    if (caption){
        data['caption'] = caption;
    }

    if (description){
        data['description'] = description;
    }

    if (typeof public_ !== 'undefined'){
        data['public'] = public_;
    }

    get_session(function(){
        return $.ajax({
            url: window._config.apiUrl + '/users/' + username + '/categories/' + category_id + '/items/' + image_id,
            type: 'PUT',
            contentType: 'application/json',
            processData: false,
            data: data,
            headers: {
                'Authorization': window._token
            },
            beforeSend: function(xhr, options){
                if (options.data){
                    options.data=JSON.stringify(options.data);
                }
            },
            error: function(x, s, e){
                console.error(this.url + ' xhr: ' + JSON.stringify(x));
                console.error(this.url + ' status: ' + s);
                console.error(this.url + ' error: ' + e);
            },
            success: function(){
                image_zoom_close();
                load_category(username, category_id, true)
            }
        })
    });
}

function delete_item_by_id(category_id, id, callable){
    if (!username){
        var username = get_username();
    }

    get_session(function(){
        return $.ajax({
            url: window._config.apiUrl + '/users/' + username + '/categories/' + category_id + '/items/' + id,
            type: 'DELETE',
            contentType: 'application/json',
            processData: false,
            headers: {
                'Authorization': window._token
            },
            beforeSend: function(xhr, options){
                if (options.data){
                    options.data=JSON.stringify(options.data);
                }
            },
            error: function(x, s, e){
                console.error(this.url + ' xhr: ' + JSON.stringify(x));
                console.error(this.url + ' status: ' + s);
                console.error(this.url + ' error: ' + e);
            },
            success: callable
        })
    });
}

//
// Functions that focus on the web UI
//

function update_address_bar(path){
    if (window._config.stage == 'local'){
        return;
    }

    let state = {'path': path};

    if (history.state && history.state.path){
        state['previous_path'] = history.state.path;
    } else {
        state['previous_path'] = undefined;
    }

    history.pushState(state, "brokenwrenches.com", path);
}

function find_item_by_child_element(element){
    let item = {};

    if (element.attr('id') && element.attr('type')){
        item['id'] = element.attr('id');
        item['type'] = element.attr('type');
        item['public'] = element.attr('public');
        item['image'] = element.attr('image');

        item['description'] = element.attr('description') ? element.attr('description') : '';

        if (element.attr('type') == 'reference'){
            return find_item_by_child_element($('.categories_container').find('#' + element.attr('id')))

        } else if (element.attr('type') == 'category'){
            item['name'] = element.attr('name');

            item['shortname'] = element.attr('shortname') ? element.attr('shortname') : '';
            item['description'] = element.attr('description') ? element.attr('description') : '';

        } else if (element.attr('type') == 'image' || element.attr('type') == 'log'){
            item['category'] = element.attr('category');
            item['date'] = element.attr('date');

            item['caption'] = element.attr('caption') ? element.attr('caption') : '';
        }

        return item;

    } else if (element.parent()){
        return find_item_by_child_element(element.parent());

    } else {
        return;
    }
}

function is_category_full_view(){
    if ($('.categories_container').attr('full') == "true"){
        return true;
    } else {
        return false;
    }
}

function set_category_full_view(full){
    if (full == "true" || full == true){
        $('.categories_container').attr('full', "true");
        return true;

    } else if (full == "false" || full == false){
        $('.categories_container').attr('full', "false");
        return false;
    }
}

function get_category_view_mode(id){
    return $('div.category#' + id).attr('view_mode')
}

export function splash(content, hide_close){
    if (hide_close){
        $("div.splash_content_header").css('display', 'none');
    } else {
        $("div.splash_content_header").css('display', '');
    }
    $("div.splash_content_variable").empty();
    $("div.splash_content_variable").append(content);
    $("div.splash").fadeIn('500').css('display','table');
    $("body").css('overflow', 'hidden');
}

export function splash_close(message){
    if (message){
        var html = '<div class=splash_close_message>' + message + '</div>';
        $("div.splash_content_variable").empty();
        $("div.splash_content_variable").append(html);
    } else {
        $("div.splash").fadeOut('500');
        $('body').css('overflow', 'auto');
        $("div.splash_content_variable").empty();
    }
}

function user_home(user){
    $('.categories_container').empty();
    set_category_full_view(false);

    if (!user){
        var user = get_username();
    }

    if (!get_username()){
        //$(".page_content_container").css({'display': 'none'});
        show_login(window.location.pathname);
        return;
    }

    get_categories(user, function(data){
        if (data.length > 0){
            data.sort(function(a, b){
                if (a.last_modified < b.last_modified){
                    return 1;
                }
                if (a.last_modified > b.last_modified){
                    return -1;
                }
                return 0;
            });

            for (let i=0; i < data.length ;i++){
                if (data.length == 1){
                    // if there is only one category, show the full thing
                    set_category_full_view(true);
                    load_category(user, data[i]['id']);

                } else {
                    load_category(user, data[i]['id']);
                }
            }
        } else {
            $('.getting_started').show();
        }
    });
}

function load_content(url, update_history){
    if (update_history){
        update_address_bar(url);
    }

    if (url){
        var [r, user, category, item] = url.split('/');

        if (user && category && item){
            get_item_by_id(user, category, item, function(ret){
                add_item_to_category(ret['item']['category'], item, ret['item']['image'],
                        ret['item']['date'], ret['item']['title'],
                        ret['item']['description'], true,
                        ret['item']['public'], ret['item']['type'])
                image_zoom(item);
            });

        } else if (user && category){
            set_category_full_view(true)
            load_category(user, category, true);

        } else if (user && user != 'user'){
            user_home(user);

        } else {
            // local stage only
            user_home();
        }
    } else {
        // default page
        user_home();
    }
}

function load_category(user, id, insert_at_top){
    // make sure getting started is not showing
    $('.getting_started').hide();

    // if page does not contain div for category, add it now to preserve the
    // order categories should be displayed.
    if ($('.categories_container').find('div#' + id).length == 0){
        if (insert_at_top){
            $('.categories_container').prepend('<div class=category id=' + id + '></div>');
        } else {
            $('.categories_container').append('<div class=category id=' + id + '></div>');
        }
    }

    if (is_category_full_view()){
        var limit = false;
    } else {
        // This needs to be large enough to show 5, not deleted, images
        var limit = 10;
    }

    get_category_by_id(user, id, limit, function(category_data){
        if (id != category_data['id']){
            // Supplied ID is shortname, need to use actual ID in DOM
            $('.categories_container').find('#' + id).attr('id', category_data['id']);
            id = category_data['id'];
        }

        var view_mode = get_category_view_mode(id);

        if (is_category_full_view()){
            // focusing on single category, remove any existing content
            $('.categories_container').empty();
            $('.categories_container').append('<div class=category id=' + id + '></div>');
            var $category = $('.categories_container').find('#' + id);

        } else {
            // summary page, empty category which may or may not be already
            // empty, but leave container in place to maintain category order
            // on page.
            var $category = $('.categories_container').find('#' + category_data['id']);
            $category.empty();
        }

        $category.attr('type', 'category');
        $category.attr('image', category_data['image']);
        $category.attr('shortname', category_data['category_shortname']);
        $category.attr('name', category_data['title']);

        $category.attr('date', category_data['date']);
        $category.attr('description', category_data['description']);

        if (category_data['public'] == "true" || category_data['public'] == true){
            $category.attr('public', "true");
        } else {
            $category.attr('public', "false");
        }

        if (view_mode){
            $category.attr('view_mode', view_mode);
        } else {
            $category.attr('view_mode', 'images');
        }

        if (category_data['image']){
            $category.append('<div class=category_image></div>');
            var $category_image = $category.find('.category_image');

            var image_url = window._config.imageUrl + '/' + get_username() + '/' + id + '/' + category_data['image'];
            $category_image.attr('style', 'background-image: url(' + image_url + ');');
        } else {
            $category.append('<div class="category_image category_image_border"></div>');
            var $category_image = $category.find('.category_image');

            $category_image.append('<div class=category_image_no_image>No Project Image.<br>Edit Project to Add Image.</div>');
        }

        if (is_category_full_view()){
            $category_image.attr('title', 'Click to Zoom');
        } else {
            $category_image.attr('title', 'Click to Expand Category');
        }

        $category_image.append('<div class=category_text_container></div>');
        var $category_text_container = $category_image.find('.category_text_container');

        $category_text_container.append('<div class=category_name></div>');
        var $category_name = $category_text_container.find('.category_name');
        $category_name.text(category_data['title']);

        $category_text_container.append('<div class=category_description></div>');
        var $category_description = $category_text_container.find('.category_description');
        if (category_data['description']){
            $category_description.text(category_data['description']);
        }

        // Open toolbar
        $category_text_container.append('<div class=category_tools></div>');
        var $category_tools = $category_text_container.find('.category_tools');

        if (get_username()){
            // Add Item
            $category_tools.append('<button class=category_tools_add_image></button>');
            var $category_tools_add_image = $category_tools.find('.category_tools_add_image');
            $category_tools_add_image.attr('title', 'Add Image or Log to Project');
            $category_tools_add_image.append('<i class=material-icons>add_box</i>');

            // Toggle category view between thumbs and log
            $category_tools.append('<button class=category_tools_toggle_view></button>');
            var $category_tools_toggle_view = $category_tools.find('.category_tools_toggle_view');
            $category_tools_toggle_view.attr('title', 'Toggle View');
            if (view_mode == 'logs'){
                $category_tools_toggle_view.append('<i class=material-icons>view_module</i>');
            } else {
                $category_tools_toggle_view.append('<i class=material-icons>view_list</i>');
            }

            // Edit Category
            $category_tools.append('<button class=category_tools_update_category></button>');
            var $category_tools_update_category = $category_tools.find('.category_tools_update_category');
            $category_tools_update_category.attr('title', 'Edit Project');
            $category_tools_update_category.append('<i class=material-icons>edit</i>');

            // toggle public visibility
            $category_tools.append('<button class=category_tools_visibility></button>');
            var $category_tools_visibility = $category_tools.find('.category_tools_visibility');

            if ($category.attr('public') == "true"){
                $category_tools_visibility.attr('title', 'Project Currently Public, Make Private');
                $category_tools_visibility.append('<i class=material-icons>visibility</i>');
            } else {
                $category_tools_visibility.attr('title', 'Project Currently Private, Make Public');
                $category_tools_visibility.append('<i class=material-icons>visibility_off</i>');
            }

            // Delete category
            $category_tools.append('<button class=category_tools_delete></button>');
            var $category_tools_delete = $category_tools.find('.category_tools_delete');
            $category_tools_delete.attr('title', 'Delete Project');
            $category_tools_delete.append('<i class=material-icons>delete</i>');
        }

        // TODO check view mode on category or somehow persist view mode
        if (is_category_full_view()){
            if (view_mode == 'logs'){
                $category.append('<div class=category_logs_full></div>');
                var $category_items = $category.find('.category_logs_full');
            } else {
                $category.append('<div class=category_images_full></div>');
                var $category_items = $category.find('.category_images_full');
            }
        } else {
            if (view_mode == 'logs'){
                $category.append('<div class=category_logs></div>');
                var $category_items = $category.find('.category_logs');
            } else {
                $category.append('<div class=category_images></div>');
                var $category_items = $category.find('.category_images');
            }
        }
        $category_items.attr('id', 'category_' + id + '_images');

        for (let ii=0; ii < category_data.items.length ;ii++){
            add_item_to_category(category_data.items[ii]['category'],
                                 category_data.items[ii]['id'],
                                 category_data.items[ii]['image_id'],
                                 category_data.items[ii]['date'],
                                 category_data.items[ii]['title'],
                                 category_data.items[ii]['description'],
                                 undefined,
                                 category_data.items[ii]['public'],
                                 category_data.items[ii]['type']);
        }
    });
}

function create_progress_indicator(category, prepend){
    var $category = $('.categories_container').find('#' + category);

    var view_mode = get_category_view_mode(category);

    if (view_mode == 'logs'){
        if ($category.find('.category_logs_full').length){
            var $category_images = $category.find('.category_logs_full');
        } else {
            var $category_images = $category.find('.category_logs');
        }
    } else {
        if ($category.find('.category_images_full').length){
            var $category_images = $category.find('.category_images_full');
        } else {
            var $category_images = $category.find('.category_images');
        }
    }

    var rand_id = Math.random().toString(36).substring(2);

    if (view_mode == 'logs'){
        if (prepend){
            $category_images.prepend('<div class=category_log_items id=' + rand_id + '><progress class=thumbnail_upload></progress></div>');
        } else {
            $category_images.append('<div class=category_log_items id=' + rand_id + '><progress class=thumbnail_upload></progress></div>');
        }
        return $category_images.find('.category_log_items#' + rand_id).children(':first');

    } else {
        if (prepend){
            $category_images.prepend('<div class=category_images_thumbnail id=' + rand_id + '><progress class=thumbnail_upload></progress></div>');
        } else {
            $category_images.append('<div class=category_images_thumbnail id=' + rand_id + '><progress class=thumbnail_upload></progress></div>');
        }
        return $category_images.find('.category_images_thumbnail#' + rand_id).children(':first');
    }
}

function add_item_to_category(category, id, image_id, date, title, description, prepend, public_, type){
    if ($('.categories_container').find('#' + category).length == 0){
        // category does not exist on page, create mock category
        $('.categories_container').prepend('<div class=category id=' + category + '></div>');
        var $category = $('.categories_container').find('div#' + category);
        $category.attr('type', 'category');
        $category.css({'display': 'none'});
        $category.attr('view_mode', 'images');
        $category.append('<div class=category_images_full></div>');
        $category.append('<div class=category_images></div>');

    } else {
        var $category = $('.categories_container').find('#' + category);
    }

    if ($category.attr('view_mode') == 'images'){
        if (is_category_full_view()){
            var $category_items = $category.find('.category_images_full');
        } else {
            var $category_items = $category.find('.category_images');
        }
    } else {
        if (is_category_full_view()){
            var $category_items = $category.find('.category_logs_full');
        } else {
            var $category_items = $category.find('.category_logs');
        }
    }

    if ($category_items.find('#' + id + '_progress').length){
        // if there is a progress indicator for this image ID, insert the
        // actual image thumbnail just after it to preserve display order
        if ($category.attr('view_mode') == 'images'){
            $('<div class=category_images_thumbnail id=' + id + '></div>').insertAfter('.category_images_thumbnail#' + id + '_progress');
        } else {
            $('<div class=category_log_items id=' + id + '></div>').insertAfter('.category_log_items#' + id + '_progress');
        }

    } else if (prepend){
        if ($category.attr('view_mode') == 'images'){
            $category_items.prepend('<div class=category_images_thumbnail id=' + id + '></div>');
        } else {
            $category_items.prepend('<div class=category_log_items id=' + id + '></div>');
        }
    } else {
        if ($category.attr('view_mode') == 'images'){
            $category_items.append('<div class=category_images_thumbnail id=' + id + '></div>');
        } else {
            $category_items.append('<div class=category_log_items id=' + id + '></div>');
        }
    }

    var $category_item = $category_items.find('#' + id);
    $category_item.hide();
    $category_item.attr('category', category);
    $category_item.attr('type', type);
    $category_item.attr('id', id);
    $category_item.attr('image', image_id);
    $category_item.attr('public', public_);
    $category_item.attr('category_shortname', $category.attr('shortname'));
    $category_item.attr('description', description);
    $category_item.attr('caption', title);

    $category_item.append('<div class=image></div>');
    $category_item.append('<div class=date></div>');
    $category_item.append('<div class=caption></div>');

    if (date && date != ''){
        $category_item.attr('date', date);
        $category_item.find('div.date').text(date);
    }

    var title_working = '';
    if (date){
        title_working = title_working.concat(date + ': ');
    }
    if (title){
        title_working = title_working.concat(title);
    } else {
        title_working = title_working.concat('Click to Zoom');
    }

    var $image = $category_item.find('div.image');
    $image.attr('title', title_working);

    $category_item.find('div.caption').text(title);

    if (get_username()){
        $category_item.append('<div class=category_images_thumbnail_tools></div>');
        var $category_images_thumbnail_tools =  $category_item.find('.category_images_thumbnail_tools');

        if ($category.attr('view_mode') == 'images' && !image_id){
            $category_images_thumbnail_tools.hide();
        }

        $category_images_thumbnail_tools.append('<button class=image_edit></button>');
        var $image_edit = $category_images_thumbnail_tools.find('.image_edit');
        $image_edit.attr('title', 'Edit');
        $image_edit.append('<i class=material-icons>edit</i>');

        $category_images_thumbnail_tools.append('<button class=category_images_thumbnail_visibility></button>');
        var $category_images_thumbnail_visibility = $category_images_thumbnail_tools.find('.category_images_thumbnail_visibility');

        if (public_ == "true" || public_ == true){
            $category_images_thumbnail_visibility.attr('title', 'Item Currently Public, Make Private');
            $category_images_thumbnail_visibility.append('<i class=material-icons>visibility</i>');
        } else {
            $category_images_thumbnail_visibility.attr('title', 'Item Currently Private, Make Public');
            $category_images_thumbnail_visibility.append('<i class=material-icons>visibility_off</i>');
        }

        $category_images_thumbnail_tools.append('<button class=category_images_thumbnail_delete></button>');
        var $category_images_thumbnail_delete = $category_images_thumbnail_tools.find('.category_images_thumbnail_delete');
        $category_images_thumbnail_delete.attr('title', 'Delete Item');
        $category_images_thumbnail_delete.append('<i class=material-icons>delete</i>');
    }

    $category_item.append('<div class=description></div>');
    if (description){
        $category_item.find('div.description').text(description);
    }

    if (! image_id){
        $image.css({'box-shadow': '0 0 0 2px #2F5B9D inset'});
        $image.append('<div class=thumbnail_log>Log Entry</div>');
    }

    if (image_id){
        $category_items.append('<img class=temp id=' + id + ' />');
        var $image_temp = $category_items.find('.temp#' + id);
        $image_temp.hide();

        var retries = 0;
        var img_timeout_id;

        if (get_username()){
            var username = get_username();
        } else {
            var username = getRequestedUsername();
        }

        $image_temp.on('error', function(){
            img_timeout_id = setTimeout(function(){
                $image_temp.removeAttr('src');
                $image_temp.attr('src', window._config.imageUrl + '/' + username + '/' + category + '/' + image_id + '?thumbnail');

                if ($image_temp.get(0).naturalWidth == 0 && retries < window._config.image_retry_count){
                    // keep retrying
                    retries++;
                } else {
                    // image load successfully, or given up
                    $(this).remove();
                    $image_temp.off('error');
                    $image_temp.off('load');
                    clearTimeout(img_timeout_id);
                }
            }, (2**retries)*1000/2);
        });

        $image_temp.on('load', function(){
            $(this).remove();

            $image_temp.off('error');
            $image_temp.off('load');

            if (img_timeout_id){
                clearTimeout(img_timeout_id);
            }

            if ($category_items.find('#' + id + '_progress').length){
                $category_items.find('#' + id + '_progress').remove();
            }

            $image.attr('style', 'background-image: url(' + window._config.imageUrl + '/' + username + '/' + category + '/' + image_id + '?thumbnail' + ');');
            $category_item.show();
        });

        $image_temp.attr('src', window._config.imageUrl + '/' + username + '/' + category + '/' + image_id + '?thumbnail');
    } else {
        $category_item.show();
    }
}

function image_zoom(id){
    var $image_zoom_content_variable = $("div.image_zoom_content_variable");
    $image_zoom_content_variable.empty();

    var item = find_item_by_child_element($('.categories_container').find('#' + id));

    if (get_username()){
        var user = get_username();
    } else {
        var user = getRequestedUsername();
    }

    if (item.type == 'category'){
        if (item.shortname){
            var cat = item.shortname;
        } else {
            var cat = item.id;
        }
    } else {
        var category = find_item_by_child_element($('.categories_container').find('#' + item.category));
        if (category.shortname){
            var cat = category.shortname;
        } else {
            var cat = item.category;
        }
    }

    if (item.type == 'category' && item.image == null){
        // trying to zoom on category image where none exists
        return
    }

    var $image_zoom_content = $("div.image_zoom_content");
    $image_zoom_content.attr('id', id);
    $image_zoom_content.attr('type', 'reference');

    $image_zoom_content_variable.append('<img class=image_zoom_image>');
    var $image_zoom_image = $("div.image_zoom_content_variable").find('.image_zoom_image');
    $image_zoom_image.attr('src', window._config.imageUrl + '/' + user + '/' + cat + '/' + item.image);

    // header
    var $image_zoom_content_header_container = $("div.image_zoom_content_header_container");
    $image_zoom_content_header_container.empty();

    $image_zoom_content_header_container.append('<div class=image_zoom_content_header></div>');
    var $image_zoom_content_header = $image_zoom_content_header_container.find('.image_zoom_content_header');

    if (item.caption || item.name){
        $image_zoom_content_header.append('<div class=image_text_caption></div>');
        var $image_text_caption = $image_zoom_content_header.find('.image_text_caption');
        $image_text_caption.text(item.caption ? item.caption : item.name);
    }
    if (item.description){
        $image_zoom_content_header.append('<div class=image_text_description></div>');
        var $image_text_description = $image_zoom_content_header.find('.image_text_description');
        $image_text_description.text(item.description);
    }
    if (item.date){
        $image_zoom_content_header.append('<div class=image_text_date></div>');
        var $image_text_date = $image_zoom_content_header.find('.image_text_date');
        $image_text_date.text(item.date);
    }

    // toolbar
    $image_zoom_content_header.append('<div class=image_tools></div>');
    var $image_tools = $image_zoom_content_header.find('.image_tools');

    $image_tools.append('<button class=image_zoom_close></button>');
    var $image_zoom_close = $image_tools.find('.image_zoom_close');
    $image_zoom_close.attr('title', 'Close Image');
    $image_zoom_close.append('<i class=material-icons>close</i>');

    $image_tools.append('<a class=image_zoom_download></a>');
    var $image_download = $image_tools.find('.image_zoom_download');
    $image_download.attr('title', 'Download Image');
    $image_download.attr('href', $image_zoom_image.attr('src') + '?download');
    $image_download.append('<i class=material-icons>file_download</i>');

    if (item.type != "category" && get_username()){
        $image_tools.append('<button class=image_edit></button>');
        var $image_edit = $image_tools.find('.image_edit');
        $image_edit.attr('title', 'Edit Image');
        $image_edit.append('<i class=material-icons>edit</i>');

        $image_tools.append('<button class=image_visibility></button>');
        var $image_visibility = $image_tools.find('.image_visibility');

        if (item.public == "true"){
            $image_visibility.attr('title', 'Item Currently Public, Make Private');
            $image_visibility.append('<i class=material-icons>visibility</i>');
        } else {
            $image_visibility.attr('title', 'Item Currently Private, Make Public');
            $image_visibility.append('<i class=material-icons>visibility_off</i>');
        }

        $image_tools.append('<button class=image_delete></button>');
        var $image_delete = $image_tools.find('.image_delete');
        $image_delete.attr('title', 'Delete Image');
        $image_delete.append('<i class=material-icons>delete</i>');
    }

    // Show the image header/toolbar based onces the image is completely
    // downloaded, adjust size of header as screen size changes to always
    // make sure the header is as wide as landscape and portait images
    $image_zoom_image.on('load', function(){
        $image_zoom_content_header.css({'display': 'block',
                'max-width': $(this).width()});

        $(window).on('resize', function(){
            $('.image_zoom_content_header').css({'max-width': $('.image_zoom_image').width()});
        });
    });

    $("div.image_zoom").fadeIn('500').css('display','table');
    $("body").css('overflow', 'hidden');
}

function image_zoom_close(){
    $("div.image_zoom").fadeOut('500');
    $('body').css('overflow', 'auto');
    $("div.image_zoom_content").removeAttr('id');
    $("div.image_zoom_content").removeAttr('type');
    $("div.image_zoom_content_header").empty();
    $("div.image_zoom_content_variable").empty();
    $(window).off('resize');

    if (history.state && history.state.previous_path){
        update_address_bar(history.state.previous_path)

    } else if (get_username()) {
        load_content('/' + get_username(), true);

    } else if(! get_username() && ! is_category_full_view()){
        window.location.href = '/?s=public-item';
    }

    return false;
}

//
// Event handlers
//

$(window).on("popstate", function(e){
    if (history.state && history.state.path){
        if($("div.image_zoom").is(':visible')){
            $("div.image_zoom").fadeOut('500');
            $('body').css('overflow', 'auto');
            $("div.image_zoom_content_header").empty();
            $("div.image_zoom_content_variable").empty();
            $(window).off('resize');
        }
        splash_close();

        load_content(history.state.path, false);

    } else if (typeof e.state != 'undefined'){
        // e.state will be undefined when clicking forward link
        history.back();
    }
});

$(document).on('click', 'button.go_home', function(){
    update_address_bar('/' + get_username());
    user_home();
});

$(document).on('click', 'button.create_category, div.getting_started', function(event){
    var html = '<div class=splash_error></div>';
    html = html.concat('<div class=splash_title>Create New Project</div>');
    html = html.concat('<form id=create_category action=""><br>');
    html = html.concat('Project Name:<input type=text id=category_name><br>');
    html = html.concat('Project Image:<input type=file id=category_file /><br>');
    html = html.concat('Project Short Name (Used for Sharing):<input type=text id=category_shortname><br>');
    html = html.concat('Description:<textarea id=description></textarea><br>');
    html = html.concat('<div class=button_container><button class=submit>Submit</button></div></form>');
    splash(html);
});

$(document).on('click', '#create_category button.submit', function(event){
    event.preventDefault();
    $('#create_category input, #create_category textarea, #create_category select').attr('readonly', 'readonly');
    $('.splash_error').text('');

    if ($("#category_shortname").val() && $("#category_shortname").val().match(/\s/)){
        $('.splash_error').text('Project short name cannot contain spaces');
        $('#create_category input, #create_category textarea, #create_category select').removeAttr('readonly');
        return
    }

    create_category(get_username(), $("#category_name").val(),
            document.getElementById("category_file").files[0],
            $("#category_shortname").val(),
            $("#description").val()).done(function(data){

        if (data['status'] != 'OK'){
            $('.splash_error').text(data['message']);
            $('#create_category input, #create_category textarea, #create_category select').removeAttr('readonly');
        }
    });

    // do not splash_close() here, we do it after the image uploads
});

$(document).on('click', 'button.send_feedback', function(event){
    var html = '<div class=splash_title>Submit Feedback</div>';
    html = html.concat('<form id=feedback action=""><br>');
    html = html.concat('Feedback:<textarea id=feedback_text></textarea><br>');
    html = html.concat('Ok if we contact you? <input type=checkbox id=feedback_followup><br>');
    html = html.concat('<div class=button_container><button class=submit>Submit</button></div></form>');
    splash(html);
});

$(document).on('click', '#feedback button.submit', function(event){
    event.preventDefault();

    $('#feedback input, #feedback textarea, #feedback select').attr('readonly', 'readonly');

    send_feedback($("#feedback_text").val(),
            $("#feedback_followup").is(':checked'));

    splash_close('Thank you for the feedback!');
});

$(document).on('click', 'button.send_referral', function(event){
    var html = '<div class=splash_error></div>';
    html = html.concat('<div class=splash_title>Refer a Friend</div>');
    html = html.concat('<form id=refer action=""><br>');
    html = html.concat('Email Address:<input type=text id=refer_email><br>');
    html = html.concat('Message:<textarea id=refer_message></textarea><br>');
    html = html.concat('<div class=button_container><button class=submit>Submit</button></div></form>');
    splash(html);
});

$(document).on('click', '#refer button.submit', function(event){
    event.preventDefault();

    $('#refer input, #refer textarea, #refer select').attr('readonly', 'readonly');

    refer_email($("#refer_email").val(), $("#refer_message").val()).done(function(data){
        if (data['status'] == 'OK'){
            splash_close('Thank you the referral! We will send them an email now.');
        } else {
            $('.splash_error').text(data['message']);
            $('#refer input, #refer textarea, #refer select').removeAttr('readonly');
        }
    });
});

$(document).on('click', 'div.category_image', function(event){
    var category = find_item_by_child_element($(this));

    let user;
    if (get_username()){
        user = get_username();
    } else {
        user = getRequestedUsername();
    }

    let cat;
    if (category.shortname){
        cat = category.shortname;
    } else {
        cat = category.id;
    }

    if (is_category_full_view()){
        update_address_bar('/' + user + '/' + cat + '/' + category.id);
        image_zoom(category.id);

    } else {
        update_address_bar('/' + user + '/' + cat);
        set_category_full_view(true)
        load_category(user, category.id, false);
    }

    return false;
});

$(document).on('click', "button.category_tools_submit_email", function(event){
    window.location.href = 'mailto:' + $(this).attr('destination');
    return false;
});

$(document).on('click', 'button.category_tools_visibility', function(){
    event.preventDefault();

    var category = find_item_by_child_element($(this));

    category.public = category.public == "true" ? "false" : "true";

    update_category(get_username(), category.id, category.name, undefined,
            category.shortname, category.description, category.public);

    return false;
});

$(document).on('click', "button.category_tools_delete", function(event){
    var category = find_item_by_child_element($(this));
    $('.categories_container').find('div#' + category.id).remove();

    delete_category_by_id(category.id).done(function(){
        if ($('.categories_container').children().length == 0){
            update_address_bar('/' + get_username());
            user_home();
        }
    });

    return false;
});

$(document).on('click', 'div.category_images_thumbnail, div.category_log_items', function(event){
    var item = find_item_by_child_element($(this));
    var category = find_item_by_child_element($('#' + item.category));

    if (! item.image){
        return false;
    }

    if (get_username()){
        var user = get_username();
    } else {
        var user = getRequestedUsername();
    }

    if (category.shortname){
        update_address_bar('/' + user + '/' + category.shortname + '/' + item.id);
    } else {
        update_address_bar('/' + user + '/' + item.category + '/' + item.id);
    }

    image_zoom(item.id);

    return false;
});

$(document).on('click', "button.category_tools_toggle_view", function(event){
    var category = find_item_by_child_element($(this));
    var $category = $('.categories_container').find('div#' + category.id);

    if (get_category_view_mode(category.id) == 'images'){
        $category.attr('view_mode', 'logs');
        $category.find('button.category_tools_toggle_view > i').text('view_module');

        $category.find('div.category_images_full').attr('class', 'category_logs_full');
        $category.find('div.category_images').attr('class', 'category_logs');
        $category.find('* > div.category_images_thumbnail').attr('class', 'category_log_items');
        $category.find('* > div.category_log_items').show();

        $category.find('* > div.category_log_items').each(function(){
            if($(this).attr('type') == 'log'){
                $(this).find('.category_images_thumbnail_tools').show();
            }
        });
    } else {
        $category.attr('view_mode', 'images');
        $category.find('button.category_tools_toggle_view > i').text('view_list');

        $category.find('div.category_logs_full').attr('class', 'category_images_full');
        $category.find('div.category_logs').attr('class', 'category_images');
        $category.find('* > div.category_log_items').attr('class', 'category_images_thumbnail');

        $category.find('* > div.category_images_thumbnail').each(function(){
            if($(this).attr('type') == 'log'){
                $(this).find('.category_images_thumbnail_tools').hide();
            }
        });
    }

    return false;
});

$(document).on('click', 'button.image_zoom_close', image_zoom_close);

$(document).on('click', 'div.image_zoom', function(){
    image_zoom_close();
    return true;
});

$(document).on('click', 'button.image_edit', function(){
    var item = find_item_by_child_element($(this));

    var html = '<div class=splash_error></div>';
    html = html.concat('<form id=image_edit><br>');
    html = html.concat('Date:<input type=text id=date ');
    if (item.date){
        html = html.concat(' value="' + item.date + '" ');
    }
    html = html.concat('/><br>');
    html = html.concat('Caption:<input type=text id=caption value="' + item.caption + '"/><br>');
    html = html.concat('Description:<textarea id=description>' + item.description + '</textarea><br>');
    html = html.concat('<input type=hidden id=category value=' + item.category + '><br>');
    html = html.concat('<input type=hidden id=image value=' + item.id + '><br>');
    html = html.concat('<input type=hidden id=public value=' + item.public + '><br>');
    html = html.concat('<div class=button_container><button class=submit>Submit</button></div></form>');
    splash(html);

    return false;
});

$(document).on('click', 'button.image_visibility', function(){
    event.preventDefault();

    var item = find_item_by_child_element($(this));

    item.public = item.public == "true" ? "false" : "true";

    update_image(get_username(), item.category, item.id, item.date, item.caption,
            item.description, item.public);

    return false;
});

$(document).on('click', 'button.image_delete', function(){
    var item = find_item_by_child_element($(this));

    image_zoom_close();

    delete_item_by_id(item.category, item.id, function(){
        load_category(get_username(), item.category);
    });

    return false;
});

$(document).on('click', "button.category_tools_add_image", function(event){
    var category = find_item_by_child_element($(this));

    var html = '<form id=add_item enctype="multipart/form-data" action=""><br>';
    html = html.concat('<div class=splash_title>Add Item to Project</div>');
    html = html.concat('Caption:<input type=text id=caption /><br>');
    html = html.concat('Description:<textarea id=description></textarea><br>');
    html = html.concat('Project Image:<input type=file id=image_image multiple/><br>');
    html = html.concat('<input type=hidden id=add_image_category value=' + category.id + '><br>');
    html = html.concat('<div class=button_container><button class=submit>Submit</button></div></form>');
    splash(html);

    return false;
});

$(document).on('click', "button.category_tools_update_category", function(event){
    var category = find_item_by_child_element($(this));

    var html = '<div class=splash_error></div>';
    html = html.concat('<div class=splash_title>Update Project</div>');
    html = html.concat('<form id=update_category action=""><br>');
    html = html.concat('Project Name:<input type=text id=category_name');
    if (category.name){
        html = html.concat(' value="' + category.name + '" ');
    }
    html = html.concat('><br>');
    html = html.concat('New Project Image:<input type=file id=category_file /><br>');
    html = html.concat('Project Short Name (Used for Sharing):<input type=text id=category_shortname value="' + category.shortname + '"><br>');
    html = html.concat('Description:<textarea id=description>' + category.description + '</textarea><br>');
    html = html.concat('<input type=hidden id=category_id value=' + category.id + '><br>');
    html = html.concat('<div class=button_container><button class=submit>Submit</button></div></form>');
    splash(html);

    return false;
});

$(document).on('click', '#update_category button.submit', function(event){
    event.preventDefault();

    $('#update_category input, #update_category textarea, #update_category select').attr('readonly', 'readonly');
    $('.splash_error').text('');

    if ($("#category_shortname").val() && $("#category_shortname").val().match(/\s/)){
        $('.splash_error').text('Project short name cannot contain spaces');
        $('#update_category input, #update_category textarea, #update_category select').removeAttr('readonly');
        return
    }

    update_category(get_username(), $("#category_id").val(),
            $("#category_name").val(),
            document.getElementById("category_file").files[0],
            $("#category_shortname").val(),
            $("#description").val()).done(function(data){

        if (data['status'] != 'OK'){
            $('.splash_error').text(data['message']);
            $('#update_category input, #update_category textarea, #update_category select').removeAttr('readonly');
        }
    });

    // do not splash_close() here, we do it after the image uploads
});

$(document).on('click', "#add_item button.submit", function(event){
    event.preventDefault();

    $('#add_item input, #add_item textarea, #add_item select').attr('readonly', 'readonly');

    var username = get_username();
    var category = $("#add_image_category").val();

    if (document.getElementById("image_image").files.length > 0){
        // image item
        for (let i = 0; i < document.getElementById("image_image").files.length; i++){
            upload_item(username, category, 'image',
                    document.getElementById("image_image").files[i],
                    $("#caption").val(), $("#description").val());
        }
    } else {
        // log item
        upload_item(username, category, 'log', undefined,
                $("#caption").val(), $("#description").val());
    }

    splash_close();
});

$(document).on('click', "#image_edit button.submit", function(event){
    event.preventDefault();

    var username = get_username();
    var category = $("#image_edit > #category").val();
    var image = $("#image_edit > #image").val();

    update_image(username, category, image, $("#date").val(),
            $("#caption").val(), $("#description").val(), $("#public").val());

    splash_close();
});

$(document).on('click', 'button.category_images_thumbnail_visibility', function(){
    event.preventDefault();

    var item = find_item_by_child_element($(this));

    item.public = item.public == "true" ? "false" : "true";

    update_image(get_username(), item.category, item.id, item.date, item.caption,
            item.description, item.public);

    return false;
});

$(document).on('click', 'button.category_images_thumbnail_delete', function(){
    var item = find_item_by_child_element($(this));

    $('#' + item.category).find('#' + item.id).remove();
    delete_item_by_id(item.category, item.id);

    return false;
});

$(document).on('click', 'button.splash_close', function(){
    if ($('#signin').length){
        splash_close();
        window.location = '/?s=signin-close';
    } else {
        splash_close();
    }
});

$(document).on('click', '#waitlistForm button.waitlist', function(event){
    event.preventDefault();
    addWaitlistEntry($("#email").val(), {}).done(function(ret){
        if (ret['status'] == 'OK') {
            $("#waitlistText").html(ret['message']);
            $("#waitlistSignup").remove();
            $("#waitlistError").remove();
        } else {
            $("#waitlistError").remove();
            $("#waitlistText").prepend("<div id=waitlistError>" + ret['message'] + "</div>")
        }
    });
});

$(document).ready(function(){
    //
    // Used for all HTML pages
    //
    let username = get_username();
    if (username){
        let html = '<button class=logout><i class="material-icons logout">lock</i></button>';
        if (window._config.stage != 'local'){
            html = html.concat('<div class=userinfo><a href="/' + username + '">' + username + '</a></div>');
        } else {
            html = html.concat('<div class=userinfo><a href="/user">' + username + '</a></div>');
        }
        $("#loginStatus").html(html);
    } else {
        let html = '<div class=userinfo id=loginbutton><a href=/signup>Sign up</a> <a href="" class=show_login>Login</a></div>';
        $("#loginStatus").html(html);
    }
    $("#footer").load("/footer.html");

    //
    // Page-specific scripts
    //
    if (window.location.pathname == "/"){
        if (window.location.toString().search('[?&]s=') > -1){
            var source = window.location.toString().substr(window.location.toString().search('[?&]s=') + 3);
            if (source.indexOf('&') > -1){
                source = source.substr(0, source.indexOf('&'));
            }
        } else {
            if (document.referrer.search('^http[s]?://t.co/') > -1){
                var source = 'twitter';
            } else if (document.referrer.search('^http[s]?://[^/]*instagram.com/') > -1){
                var source = 'instagram';
            } else {
                var source = 'organic';
            }
        }

        if (window.location.toString().search('[?&]c=') > -1){
            var campaign = window.location.toString().substr(window.location.toString().search('[?&]c=') + 3);
            if (campaign.indexOf('&') > -1){
                campaign = campaign.substr(0, campaign.indexOf('&'));
            }
        }

        if (campaign){
            increment_metric('landing-page-load',
                    [{'Name': 'source', 'Value': source},
                     {'Name': 'campaign', 'Value': campaign}]);
        } else {
            increment_metric('landing-page-load',
                    [{'Name': 'source', 'Value': source}]);
        }

        if (document.cookie.indexOf('source') == -1){
            document.cookie = 'source=' + source + '; Secure; SameSite=strict';
            if (campaign){
                document.cookie = 'campaign=' + campaign + '; Secure; SameSite=strict';
            }
        }

        if (window.location.toString().indexOf('?') > -1){
            history.pushState(null, "", location.href.split("?")[0]);
        }

    } else if (window.location.pathname.search('^/signup/') > -1){
        show_signup();

    } else if (window.location.pathname.search('^/blog/') > -1 || window.location.pathname.search('\.html$') > -1){
        increment_metric('page-load',
        [{'Name': 'page', 'Value': window.location.pathname}]);

    } else { // /user/index.html
        load_content(window.location.pathname, true);
        if (username){
            $('div.toolbar_button').css({'display': 'block'})
        } else {
            document.cookie = 'source=public-item;'
        }
    }
});
